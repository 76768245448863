
<template>
	<div class="box">
		<div class='title' style=' padding-top:20px'>
			<el-form style="display:flex;flex-wrap:wrap" :model="searchlist"  label-width="100px" ref='searchlist'>
				  <!-- v-for循环截取循环的数组-->
					<el-form-item v-for="(item) in searchType.slice(0,1)" :key='searchType.indexOf(item)' :label="item.title">
						<el-select clearable style="width:220px !important" :placeholder="'请输入'+item.title+'查询'" v-model='searchlist[item.formName]'>
							<el-option label="待审核" value="0"></el-option>
							<el-option label="审核通过 " value="1"></el-option>
							<el-option label="审核不通过 " value="2"></el-option>
						</el-select>
			    	</el-form-item>
					<el-form-item v-for="(item) in searchType.slice(1,5)" :key='searchType.indexOf(item)' :label="item.title">
						<el-input style="width:220px !important" :placeholder="'请输入'+item.title+'查询'" v-model='searchlist[item.formName]'></el-input>
			    	</el-form-item>
               		<el-form-item >
						<div style="display:flex;align-items:center">
							<el-button type='primary' @click='search'>
			  	       			<i  class="el-icon-search" style="font-size: 14px;color: white;"></i>搜索       
			        		</el-button>
						</div>
                	</el-form-item>
            </el-form>
		</div>
		<div class="showdatanumber">共查询数据<span>{{ fenye.totalnum }}</span>条</div>  
		<div >
			<el-table
			  :data="tableData"
			  border
			  style="width: 100%;margin-top:20px;background-color:transition"
			  stripe>
			 <el-table-column v-for='(item) in formtype.slice(0,5)'
			    :prop="item.formvalue"
			    :label="item.name"
				align="center"
				:key ="formtype.indexOf(item)">
			  </el-table-column>
			  <el-table-column v-for='(item) in formtype.slice(5,6)'
			    :prop="item.formvalue"
			    :label="item.name"
				align="center"
				:key ="formtype.indexOf(item)"
				:formatter="checkflag">
			  </el-table-column>
			  <el-table-column v-for='(item) in formtype.slice(6,7)'
			    :prop="item.formvalue"
			    :label="item.name"
				align="center"
				:key ="formtype.indexOf(item)">
			  </el-table-column>
			  <el-table-column
			    label="审核"
                width="150"
				align="center">
				<div slot-scope="scope" style="display:flex;flex-wrap: wrap;justify-content:center">
					<el-button :disabled='scope.row.checkflag==1' type="primary" @click="change(scope.row)" >审核</el-button>
				</div>
				</el-table-column><!-- 不同状态对应操作-->
			</el-table>
		</div>
		

		<div class="dialog">
			<el-dialog
			:close-on-click-modal="false"
		  	:visible.sync="formDialog"
			v-if='formDialog'
			append-to-body
		  	width="70%">
				<span style="font-size: 20px;color:white" slot="title">实名信息补全审核</span>
                <AddForm :Mail='accountUuid'></AddForm>
			</el-dialog>
		</div>

		
		<div class='fenyekuang'>
			<el-pagination @size-change="sizeChange"
			@current-change="currentChange" :current-page="currentPage" 
			:page-sizes="fenye.pagesizes" :page-size="fenye.pagesize" 
			layout="total, sizes, prev, pager, next, jumper" :total="fenye.totalnum">
			</el-pagination>
		</div>
		<div class='shuiyin'>
		  <div>{{this.GLOBAL.phone()}}</div>
		  <div>{{this.GLOBAL.phone()}}</div>
		  <div>{{this.GLOBAL.phone()}}</div>
		  <div>{{this.GLOBAL.phone()}}</div>
		  <div>{{this.GLOBAL.phone()}}</div>
		  <div>{{this.GLOBAL.phone()}}</div>
		  <div>{{this.GLOBAL.phone()}}</div>
		  <div>{{this.GLOBAL.phone()}}</div>
		  <div>{{this.GLOBAL.phone()}}</div>
	  </div>
	</div>
</template>

<script>
	import {daishenList} from '@/api/shiminglist/shiminglist.js'
    import AddForm from './form'
	export default {
        components:{
			AddForm,
        },
		mounted(){
			this.GLOBAL.shuquId().then(PromiseResult=>{
				this.searchlist.arealevel=PromiseResult
				this.getList()
			})
		},
        computed:{
			
        },
	    data() {	
	      return {
	        tableData: [],//获取的表格数据
			formDialog:false,//弹出框
			formtype:[
				{name:'序号',formvalue:'xuhao'},
				{name:'社区名称',formvalue:'areaname'},
				{name:'姓名',formvalue:'name'},
				{name:'电话',formvalue:'phone'},
				{name:'身份证号',formvalue:'personcard'},
				{name:'状态',formvalue:'checkflag'},
				{name:'提交时间',formvalue:'createtime'},
			],//表单须填数据模板 
			searchlist:{
				arealevel:'',
				checkflag:'0',
				personcard:'',
				address:'',
				name:'',
				phone:'',
			},//搜索数据
			searchType:[
				{title:'审核状态',formName:'checkflag'},
				{title:'身份证号',formName:'personcard'},
				{title:'居住地址',formName:'address'},
				{title:'姓名',formName:'name'},
				{title:'联系电话',formName:'phone'},	
			],//搜索框成员
			currentPage: 1,//当前页
			fenye: {
				pagesizes: [10, 20, 50],
				pagesize: 10,
				totalnum: 0
			},//分页数据
			accountUuid:'',//账户uuid
	      }
	    },
		methods: {
		  search(){
			this.currentPage=1
			this.getList()
		  },//搜索请求
		  sizeChange(val) {
		  	this.fenye.pagesize = val;
		  	this.currentPage = 1;
            this.getList()
		  },//展示条数改变时
		  currentChange(val) {
		  	this.currentPage = val;
            this.getList()
		  },//当前页改变时
		  getList(){
			  daishenList({
				  userid:this.GLOBAL.adminId(),
				  arealevel:this.searchlist.arealevel,
				  checkflag:this.searchlist.checkflag,
				  personcard:this.searchlist.personcard,
				  address:this.searchlist.address,
				  name:this.searchlist.name,
				  phone:this.searchlist.phone,
				  pageSize:this.fenye.pagesize,
				  pageNum:this.currentPage,
			  }).then(res=>{
				  if(res.result==200){
					  this.tableData=res.detail.list
					  this.fenye.totalnum=res.detail.totalRow
					  res.detail.list.map((item,index)=>{
						  item.xuhao=index+1
					  })  
				  }else{
					  this.$message.error(res.description);
				  }
			  })
		  },//获取表格数据和查询请求
		  closeDialog(){
			  this.formDialog=false
		  },//关闭表格
		  change(row){
			this.formDialog=true
			this.accountUuid=row.accountuuid
		  },//详情框
		  shequName(row){
			  return row.areaname
		  },//社区名称
		  checkflag(row){
			  if(row.checkflag==0){
				  return "待审核"
			  }else if(row.checkflag==1){
				  return "审核通过"
			  }else{
				  return "审核不通过"
			  }
		  },//用户状态
		},
	  }
</script>
<style lang='scss'>
	.showdatanumber{
      margin: 0 10px 10px;
      color: #409EFF;
      span{
        margin: 0 5px;
        font-size: 20px;
      }
    }
	.box{
		margin:10px;
		position: relative;
		z-index: 1;
		.el-table th , .el-table tr{
			background-color: transparent;
		}
		.el-table{
			background-color: transparent;	
		}
		
		.shuiyin{
			z-index: -1;
			position:absolute;
			width: 100%;
			height: 100%;
			top: 20%;
			left: 0%;
			font-size: 40px;
			opacity: 0.1;
			display:grid;
			grid-template-columns:repeat(3,33.33%);
    	    grid-template-rows:repeat(3,33.33%);
			div{
				transform: translate(-50%,-50%);
				transform: rotate(-20deg);
			}
		}
	}
</style>
<style scoped lang='scss'>
	.playBtn{
		padding:5px !important;
		margin-left: 4px !important;
	}
	.title{
		width: 100%;
		display: flex;
        align-items: center;
	}
	.fenyekuang{
		  width: 100%;
		  height:50px;
		  margin-top: 1px;
		  display: flex;
		  justify-content: flex-end;
		  align-items: center;
	}
	
</style>


