<template>
  <div class="addHomeForm">
    <div class="box">
      <div class="shuiyin">
        <div>{{ this.GLOBAL.phone() }}</div>
        <div>{{ this.GLOBAL.phone() }}</div>
        <div>{{ this.GLOBAL.phone() }}</div>
        <div>{{ this.GLOBAL.phone() }}</div>
        <div>{{ this.GLOBAL.phone() }}</div>
        <div>{{ this.GLOBAL.phone() }}</div>
        <div>{{ this.GLOBAL.phone() }}</div>
        <div>{{ this.GLOBAL.phone() }}</div>
        <div>{{ this.GLOBAL.phone() }}</div>
      </div>
      <div class="account-info">
        <div style="font-size: 20px">
          <i class="el-icon-s-unfold"></i> 账户详情
        </div>
        <div></div>
        <div></div>

        <div style="color: red">用户编号：{{ accountInfo.uuid }}</div>
        <div style="color: red">
          小程序唯一标识：{{ accountInfo.miniopenid }}
        </div>
        <div></div>

        <div>昵称：{{ accountInfo.nickname }}</div>
        <div>手机号：{{ accountInfo.phone }}</div>
        <div>
          用户状态：
          <span style="color: red" v-if="accountInfo.userstatus == 0"
            >临时用户</span
          >
          <span style="color: green" v-if="accountInfo.userstatus == 1"
            >实名用户</span
          >
        </div>

        <div>总积分：{{ accountInfo.point }}</div>
        <div>可用积分：{{ accountInfo.usablepoint }}</div>
        <div>社区名称：{{ accountInfo.levelname }}</div>

        <div>最近登录时间：{{ accountInfo.logintime }}</div>
        <div>创建时间：{{ accountInfo.creattime }}</div>
        <div v-if="accountInfo.userstatus == 0">
          临时用户过期时间：{{ accountInfo.overtime }}
        </div>
      </div>
      <div class="user-info">
        <div style="font-size: 20px">
          <i class="el-icon-user-solid"></i> 客户详情
        </div>
        <div></div>
        <div></div>

        <div><i class="el-icon-s-custom"></i>姓名：{{ userInfo.name }}</div>
        <div><i class="el-icon-male"></i>性别：{{ userInfo.sex }}</div>
        <div>
          <i class="el-icon-c-scale-to-original"></i>身份证号：{{
            userInfo.personcard
          }}
        </div>

        <div><i class="el-icon-phone"></i>联系方式：{{ userInfo.phone }}</div>
        <div>
          <i class="el-icon-office-building"></i>详细地址：{{
            userInfo.address
          }}
        </div>
        <div></div>

        <div></div>
        <div style="justify-self: center">
          <el-radio v-model="radio" label="通过" border>通过</el-radio>
          <el-radio v-model="radio" label="不通过" border>不通过</el-radio>
        </div>
        <div></div>
      </div>
      <el-form
        label-position="right"
        label-width="100px"
        :model="ruleForm"
        ref="ruleForm"
        class="demo-ruleForm"
      >
        <div style="display: flex" v-show="radio == '通过'">
          <el-form-item
            style="flex: 1"
            v-for="item in formTitle.slice(0, 1)"
            :key="formTitle.indexOf(item)"
            :label="item.title"
          >
            <el-input
              v-model="ruleForm[item.formName]"
              :placeholder="'请输入' + item.title"
            ></el-input>
          </el-form-item>
          <el-form-item
            style="flex: 1"
            v-for="item in formTitle.slice(1, 2)"
            :key="formTitle.indexOf(item)"
            :label="item.title"
          >
            <el-select
              style="width: 100%"
              v-model="ruleForm[item.formName]"
              :placeholder="'请输入' + item.title"
            >
              <el-option
                v-for="item in xiaoquList"
                :key="item.regioncode"
                :label="item.regionname"
                :value="item.regioncode"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            style="flex: 1"
            v-for="item in formTitle.slice(2, 3)"
            :key="formTitle.indexOf(item)"
            :label="item.title"
          >
            <el-input
              v-model="ruleForm[item.formName]"
              :placeholder="'请输入' + item.title"
            ></el-input>
          </el-form-item>
        </div>
        <div style="display: flex" v-show="radio == '通过'">
          <el-form-item
            style="flex: 1"
            v-for="item in formTitle.slice(3, 5)"
            :key="formTitle.indexOf(item)"
            :label="item.title"
          >
            <el-input
              v-model="ruleForm[item.formName]"
              :placeholder="'请输入' + item.title"
            ></el-input>
          </el-form-item>
          <el-form-item
            style="flex: 1"
            v-for="item in formTitle.slice(6, 7)"
            :key="formTitle.indexOf(item)"
            :label="item.title"
          >
            <el-select
              style="width: 100%"
              v-model="ruleForm[item.formName]"
              :placeholder="'请输入' + item.title"
            >
              <el-option label="户籍实名" value="1"></el-option>
              <el-option label="流动实名" value="2"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <div style="display: flex">
          <el-form-item
            style="flex: 1"
            v-for="item in formTitle.slice(5, 6)"
            :key="formTitle.indexOf(item)"
            :label="item.title"
          >
            <el-input
              type="textarea"
              :rows="3"
              v-model="ruleForm[item.formName]"
              :placeholder="'请输入' + item.title"
            ></el-input>
          </el-form-item>
        </div>

        <el-form-item label-width="0">
          <div style="display: flex; justify-content: space-between">
            <div style="flex: 1"></div>
            <el-button
              class="submitBtn"
              type="primary"
              @click="submitForm('ruleForm')"
              >确定</el-button
            >
            <div style="flex: 0.5"></div>
            <el-button
              class="resetBtn"
              type="info"
              @click="resetForm('ruleForm')"
              >重置</el-button
            >
            <div style="flex: 0.5"></div>
            <el-button class="resetBtn" type="warning" @click="closeDialog()"
              >关闭</el-button
            >
            <div style="flex: 1"></div>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import {
  shenHe,
  mailBuQuan,
  xiaoquList,
} from "@/api/shiminglist/shiminglist.js";
import { kehuDetail } from "@/api/kehulist/kehulist.js";
export default {
  props: {
    Mail: {
      type: String,
    },
  },

  mounted() {
    kehuDetail({
      userid: this.GLOBAL.adminId(),
      accountuuid: this.Mail,
    }).then((res) => {
      if (res.result == 200) {
        console.log(res.detail);
				let accountInfo = res.detail.accountinfo
				if(accountInfo){
					this.accountInfo = res.detail.accountinfo;
				}
        this.userInfo = res.detail.userinfo;
        this.ruleForm.personcard = this.userInfo.personcard;
        console.log(res);
        xiaoquList({
          userid: this.GLOBAL.adminId(),
          levelcode: res.detail.userinfo.communitycode,
        }).then((res) => {
          if (res.result == 200) {
            this.xiaoquList = res.detail;
            console.log(res);
          } else {
            this.$message.error(res.description);
          }
        });
      } else {
        this.$message.error(res.description);
      }
    });
  }, //新增还是会传递上次点修改得到的参数，所以新增传递一个参数判断，打开时清空表单
  data() {
    return {
      radio: "",
      accountInfo: {},
      userInfo: {},
      ruleForm: {
        personcard: "",
        regioncode: "",
        building: "",
        danyuanhao: "",
        roomnum: "",
        msg: "",
        userstate: "",
      }, //表单值绑定
      formTitle: [
        { title: "身份证号", formName: "personcard" },
        { title: "小区", formName: "regioncode" },
        { title: "楼栋号", formName: "building" },
        { title: "单元号", formName: "danyuanhao" },
        { title: "房间号", formName: "roomnum" },
        { title: "审核备注", formName: "msg" },
        { title: "实名类型", formName: "userstate" },
      ], //空间标题名和对应表单字段名
      xiaoquList: [],
    };
  },
  computed: {},

  methods: {
    closeDialog() {
      this.$parent.$parent.formDialog = false;
    }, //获取父组件弹出框参数设置关闭
    submitForm() {
      if (this.radio == "通过") {
        if (
          this.ruleForm.personcard == "" ||
          this.ruleForm.personcard.length != 18
        ) {
          this.$message.error("请输入正确身份证号");
          return;
        }
        if (this.ruleForm.regioncode == "") {
          this.$message.error("请选择小区");
          return;
        }
        if (
          this.ruleForm.building == "" ||
          isNaN(Number(this.ruleForm.building)) == true
        ) {
          this.$message.error("请输入楼栋号,必须为数字");
          return;
        }
        if (
          this.ruleForm.danyuanhao == "" ||
          isNaN(Number(this.ruleForm.danyuanhao)) == true
        ) {
          this.$message.error("请输入单元号,必须为数字");
          return;
        }
        if (
          this.ruleForm.roomnum == "" ||
          isNaN(Number(this.ruleForm.roomnum)) == true
        ) {
          this.$message.error("请输入房间号,必须为数字");
          return;
        }
        if (this.ruleForm.msg == "") {
          this.$message.error("请输入审核备注");
          return;
        }
        if (this.ruleForm.userstate == "") {
          this.$message.error("请选择实名类型");
          return;
        }
        mailBuQuan({
          userid: this.GLOBAL.adminId(),
          uuid: this.userInfo.uuid,
          regioncode: this.ruleForm.regioncode,
          personcard: this.ruleForm.personcard,
          building: this.ruleForm.building,
          danyuanhao: this.ruleForm.danyuanhao,
          roomnum: this.ruleForm.roomnum,
        }).then((res) => {
          if (res.result == 200) {
            shenHe({
              userid: this.GLOBAL.adminId(),
              uuid: this.userInfo.uuid,
              checkflag: 1,
              msg: this.ruleForm.msg,
              userstate: this.ruleForm.userstate,
            }).then((res) => {
              if (res.result == 200) {
                this.$message.success("审核通过成功！");
                this.resetForm();
                this.$parent.$parent.formDialog = false;
                this.$parent.$parent.getList();
              } else {
                this.$message.error(res.description);
              }
            });
          } else {
            this.$message.error(res.description);
          }
        });
      } else if (this.radio == "不通过") {
        if (this.ruleForm.msg == "") {
          this.$message.error("请输入审核备注");
          return;
        }
        shenHe({
          userid: this.GLOBAL.adminId(),
          uuid: this.userInfo.uuid,
          checkflag: 2,
          msg: this.ruleForm.msg,
        }).then((res) => {
          if (res.result == 200) {
            this.$message.success("审核不通过成功！");
            this.resetForm();
            this.$parent.$parent.formDialog = false;
            this.$parent.$parent.getList();
          } else {
            this.$message.error(res.description);
          }
        });
      } else {
        this.$message.error("请先选择通不通过");
      }
    }, //提交按钮
    resetForm() {
      this.ruleForm = {
        personcard: "",
        regioncode: "",
        building: "",
        danyuanhao: "",
        roomnum: "",
        msg: "",
        userstate: "",
      };
    }, //重置按钮
  },
};
</script>

<style>
</style>
<style scoped lang='scss'>
.box {
  position: relative;
  z-index: 1;
  .shuiyin {
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 5%;
    left: 0%;
    font-size: 40px;
    opacity: 0.1;
    display: grid;
    grid-template-columns: repeat(3, 33.33%);
    grid-template-rows: repeat(3, 33.33%);
    div {
      transform: translate(-50%, -50%);
      transform: rotate(-45deg);
    }
  }
}
.addHomeForm .el-form-item {
  margin: 20px !important;
}
.account-info {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 33.33%);
  grid-template-rows: repeat(5, 40px);
}
.user-info {
  width: 100%;
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(3, 33.33%);
  grid-template-rows: repeat(4, 40px);
}
</style>
